import { PAGES_WITHOUT_AUTH } from '~/utils/constants/auth';

export const state = () => ({
    context: null,
    modal: null,
    genericModal: null,
    editorPage: null,
    accordion: null,
    isPreview: false,
    previewMode: false,
    responsiveMode: 'xl',
});

export const getters = {
    modal(state) {
        return state.modal;
    },
    genericModal(state) {
        return state.modal;
    },
    isPreview(state) {
        return state.isPreview;
    },
    previewMode(state) {
        return state.previewMode;
    },
};

export const mutations = {
    setModal: (state, val) => {
        console.log('setting modal to: ', val);
        state.modal = val;
    },
    setGenericModal: (state, data) => {
        state.genericModal = data;
    },
    setEditorPage: (state, slug) => {
        state.editorPage = '/' + slug;
    },
    setAccordion: (state, id) => {
        state.accordion = id;
    },
    setPreview: (state, val) => {
        state.isPreview = val;
    },
    setPreviewMode: (state, val) => {
        state.previewMode = val;
    },
    setResponsiveMode: (state, val) => {
        state.responsiveMode = val;
    },
    setContext: (state, val) => {
        state.context = val;
    },
};

export const actions = {
    async nuxtServerInit({ commit }, { $config, $axios, req, route }) {
        // Avoid requesting the API for the healthcheck page
        // req can be null when it's called thourgh the npx nuxt generate command
        // If the page is generated (process.server === true) => the current page path is retrieve by route?.path
        // If the page is dynamically rendered (process.server === false) => the current page path is retrieve by req?.url
        if (
            (process.server && !PAGES_WITHOUT_AUTH.includes(route?.path)) ||
            (!process.server && !PAGES_WITHOUT_AUTH.includes(req?.url))
        ) {
            try {
                const { access_token: authToken } = await $axios.$post(
                    '/auth/login',
                    null,
                    {
                        params: {
                            email: $config.API_EMAIL,
                            password: $config.API_PASSWORD,
                        },
                    },
                );
                commit('api/setAuthToken', authToken);
            } catch (e) {
                // TODO: what do when can't login?
            }
        }
    },
};
